import { ButtonLoader } from '@/UI/loader'
import { RadioButtonGroup } from '@/UI/radio-button-group'
import { DefaultSelect, NumberInput } from 'apptimizm-ui'
import { Ref, computed } from 'vue'
import AuditorReport from './auditor-report'

interface IModal {
  body: () => JSX.Element
  footer: (submit: () => void, isLoading: boolean) => JSX.Element
}

interface Report {
  name: string
  endpoint?: string
  modal: (form: Ref<ReportForm>) => IModal
  title?: string
  description?: string
}

interface ISelect {
  name: string
  value: string
}

const footer4Modal = (submit: () => void, isLoading: boolean, disabled: boolean) => (
  <button disabled={disabled} class='primary-button mt-4' onClick={submit}>
    <ButtonLoader hidden={!isLoading}/>
    Сформировать
  </button>
)

const otherReports = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => !form.value.excel.name)

  return {
    body: () => (
      <div class='mt-3'>
        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const reportForAuditor = (form: Ref<ReportForm>): IModal => {
  const setAuditor = (item: ListElement) => {
    form.value.auditor = item
  }
  const disabled = computed(() => !form.value.excel.name || !form.value.auditor.id)

  return {
    body: () => (
      <div class='mt-3'>
        <DefaultSelect
          class="f12"
          items={rolesListReport.map(v => ({ id: v.value, name: v.name, old: v.old }))}
          modelValue={form.value.customSelectRole}
          placeholder="Выберите роль"
          onValueChange={(v: { name: string, id: string, old: string }) => {
            form.value.customSelectRole = { name: v.name, id: v.id }
            form.value.taskType = v.old
          }}
          constantPlaceholder={false}
          idKey="id"
          titleKey="name"
        />

        {form.value.customSelectRole.id
          ? <AuditorReport form={form} setItem={setAuditor}/>
          : ''
        }

        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const renderReportTypeSelect = (form: Ref<ReportForm>) => (
  <label class='mt-2'>
    <DefaultSelect
      class="f12"
      items={reportType.map(v => ({ id: v.value, name: v.name }))}
      modelValue={form.value.excel}
      placeholder="Тип документа"
      onValueChange={(v: { name: string, id: string }) => { form.value.excel = { name: v.name, value: v.id } }}
      constantPlaceholder={false}
      idKey="value"
      titleKey="name"
    />
  </label>
)

const renderGroupByList = (form: Ref<ReportForm>) => (
  <label class="mt-2">
    <DefaultSelect
      items={groupByList.map(v => ({ id: v.value, name: v.name }))}
      modelValue={form.value.groupBy}
      placeholder="Группировка"
      onValueChange={(v: { name: string, id: string }) => { form.value.groupBy = { name: v.name, value: v.id } }}
      constantPlaceholder={false}
      idKey="value"
      titleKey="name"
    />
  </label>
)

const renderInv3Inv19 = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => {
    const { include, groupBy, excel } = form.value
    return !include.value || !groupBy.value || !excel.name
  })

  return {
    body: () => (
      <div class='mt-3'>
        <label>
          <DefaultSelect
            items={includeList.map(v => ({ id: v.value, name: v.name }))}
            modelValue={form.value.include}
            placeholder="Включить в отчет"
            onValueChange={(v: { id: string, name: string }) => { form.value.include = { value: v.id, name: v.name } }}
            constantPlaceholder={false}
            idKey="value"
            titleKey="name"
          />
        </label>
        {renderGroupByList(form)}
        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const renderDiffZonesNotFoundRests = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => {
    const { discrepancyIn, groupBy, moreThan, excel } = form.value
    return !discrepancyIn.value || !groupBy.value || moreThan < 1 || !excel.name
  })

  return {
    body: () => (
      <div class='mt-3'>
        <label>
          <DefaultSelect
            items={discrepancyInList.map(v => ({ name: v.name, id: v.value }))}
            modelValue={form.value.discrepancyIn}
            placeholder="Включить в отчет"
            onValueChange={(v: { id: string, name: string }) => { form.value.discrepancyIn = { value: v.id, name: v.name } }}
            constantPlaceholder={false}
            idKey="value"
            titleKey="name"
          />
        </label>
        {renderGroupByList(form)}
        <label class="mt-2">
          <NumberInput
            class={`${!form.value.moreThan ? 'hide-zero' : 'input-placeholder-up'}`}
            modelValue={form.value.moreThan}
            onValueChange={(v: number) => { form.value.moreThan = parseInt(String(v || 0)) } }
            placeholder="Более чем на"
          />
        </label>
        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const renderDiscAccountingQuantity = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => {
    const { groupBy, excel } = form.value
    return !groupBy.value || !excel.name
  })

  return {
    body: () => (
      <div class='mt-1'>
        {renderGroupByList(form)}
        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const renderAuditorReport = (form: Ref<ReportForm>): IModal => {
  const setAuditor = (item: ListElement) => {
    form.value.auditor = item
  }

  const disabled = computed(() => {
    const { auditor, excel } = form.value
    return !auditor?.id || !excel.name
  })

  return {
    body: () => (
      <div class="mt-3">
        <AuditorReport form={form} setItem={setAuditor}/>
        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const renderInvInzones = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => {
    const { serialNumberStart, serialNumberEnd, excel } = form.value
    return serialNumberStart < 1 ||
      serialNumberEnd < 1 ||
      serialNumberStart > serialNumberEnd ||
      !excel.name
  })

  return {
    body: () => (
      <div class='mt-3'>
        <div class='primary-grid-2'>
          <label>
            <NumberInput
              class={`${!form.value.serialNumberStart ? 'hide-zero' : 'input-placeholder-up'}`}
              modelValue={form.value.serialNumberStart}
              onValueChange={(v: number) => { form.value.serialNumberStart = parseInt(String(v || 0)) } }
              placeholder='Зоны с...'
            />
          </label>
          <label>
            <NumberInput
              class={`${!form.value.serialNumberEnd ? 'hide-zero' : 'input-placeholder-up'}`}
              modelValue={form.value.serialNumberEnd}
              onValueChange={(v: number) => { form.value.serialNumberEnd = parseInt(String(v || 0)) } }
              placeholder='По...'
            />
          </label>
        </div>
        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

function body4barcodeMatches (form: Ref<ReportForm>, isExcludeQuantity: boolean) {
  return (<div class='mt-3'>
    <label>
      <DefaultSelect
        class="f12"
        items={outputDataList.map(v => ({ id: v.value, name: v.name }))}
        modelValue={form.value.include}
        placeholder='Выводить данные'
        onValueChange={(v: { id: string, name: string }) => { form.value.include = { value: v.id, name: v.name } }}
        constantPlaceholder={false}
        idKey="value"
        titleKey="name"
      />
    </label>
    {isExcludeQuantity && <label class='mt-2'>
      <NumberInput
        class={`${!form.value.lessThan ? 'hide-zero' : 'input-placeholder-up'}`}
        modelValue={form.value.lessThan}
        onValueChange={(v: number) => { form.value.lessThan = parseInt(String(v || 0)) } }
        placeholder='Кол-во совпадений, не менее'
      />
    </label>}
    {renderReportTypeSelect(form)}
  </div>)
}

const renderBarcodeMatches = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => {
    const { include, lessThan, excel } = form.value
    return !include.value || lessThan < 1 || !excel.name
  })

  return {
    body: () => body4barcodeMatches(form, true),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const renderBarcodeMatchesExcludeQuantity = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => {
    const { groupBy, excel } = form.value
    return !groupBy.value || !excel.name
  })

  return {
    body: () => (
      <>
        {renderGroupByList(form)}
        {renderReportTypeSelect(form)}
      </>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}

const renderListOfDiscrepancies = (form: Ref<ReportForm>): IModal => {
  const disabled = computed(() => {
    const { listOfDiscrepancies, excel } = form.value
    return !listOfDiscrepancies.value || !excel.name
  })
  return {
    body: () => (
      <div class='mt-3'>
        <RadioButtonGroup
          items={listOfDiscrepanciesList as any}
          activeElement={form.value.listOfDiscrepancies as any}
          onValuChange={(v) => { form.value.listOfDiscrepancies = v }}
        />
        {renderReportTypeSelect(form)}
      </div>
    ),
    footer: (submit, isLoading) => footer4Modal(submit, isLoading, disabled.value)
  }
}
// раскомментировать отчёты в списке после правок на бэке
const reportData: Report[] = [
  { name: 'Инв-3', endpoint: 'inv-3/', modal: renderInv3Inv19, description: 'Инвентаризационная опись' },
  { name: 'Инв-19', endpoint: 'inv-19/', modal: renderInv3Inv19, description: 'Сличительная ведомость' },
  // { name: 'Расхождения по зонам', endpoint: 'diff-zones/', modal: renderDiffZonesNotFoundRests },
  // { name: 'Ненайденные остатки', endpoint: 'not-found-rests/', modal: renderDiffZonesNotFoundRests },
  { name: 'Отчет аудитора', endpoint: 'auditor-report/', modal: reportForAuditor },
  { name: 'Общее кол-во ТМЦ по зонам', endpoint: 'inv-in-zones/', modal: renderInvInzones },
  { name: 'Re Трейдинг сличительная', endpoint: 'differences/', modal: otherReports },
  // { name: 'Отчет по ошибкам', endpoint: 'errors-report/', modal: otherReports },
  // { name: 'Непросчитанные зоны', endpoint: 'not-counted-zones/', modal: otherReports },
  { name: 'Совпадение ШК по зонам', endpoint: 'barcode-matches/', modal: renderBarcodeMatches },
  { name: 'Совпадения Data Matrix по зонам', endpoint: 'data-matrix-matches/', modal: renderBarcodeMatchesExcludeQuantity },
  { name: 'Отчет по подозрительным ШК', endpoint: 'suspicious-barcodes/', modal: otherReports },
  { name: 'Расхождения с учетным кол-вом', endpoint: 'disc-accounting-quantity/', modal: renderDiscAccountingQuantity },
  { name: 'L&G сличительная', endpoint: 'lg-numeral/', modal: otherReports },
  { name: 'Ведомость расхождения Концепт Груп', endpoint: 'list-of-discrepancies/', modal: renderListOfDiscrepancies }
]

const includeList: ISelect[] = [
  { name: 'Все', value: 'all' },
  { name: 'Только опознанные', value: 'only_identified' },
  { name: 'Только неопознанные', value: 'only_unidentified' },
  { name: 'Найденные по коду товара', value: 'found_by_product_code' }
]

const groupByList: ISelect[] = [
  { name: 'По штрих-коду', value: 'by_barcode' },
  { name: 'По коду товара', value: 'by_product_code' }
]

const discrepancyInList: ISelect[] = [
  { name: 'Сумме', value: 'sum' },
  { name: 'Количеству', value: 'count' },
  { name: 'Цене', value: 'price' }
]

const outputDataList: ISelect[] = [
  { name: 'В разрезе складов', value: 'in_warehouses' },
  { name: 'Все', value: 'all' }
]

const listOfDiscrepanciesList: ISelect[] = [
  { name: 'Полный список', value: 'all' },
  { name: 'Только расхождения', value: 'test' }
]

const reportType: ISelect[] = [
  { name: 'Excel', value: 'true' },
  { name: 'PDF', value: 'false' }
]

const rolesListReport : { name: string, value: string, old: string }[] = [
  { name: 'Внешний аудитор', value: 'auditor_external', old: 'auditor_external' },
  { name: 'Аудитор', value: 'auditor', old: 'auditor' },
  { name: 'Аудитор УК', value: 'auditor', old: 'auditor_controller' }
]

interface ListElement {
  id: string
  name: string
}

class ReportForm {
  project: string = ''
  groupBy: ISelect = { name: '', value: '' }
  include: ISelect = { name: '', value: '' }
  moreThan: number = 0
  discrepancyIn: ISelect = { name: '', value: '' }
  auditor: ListElement = { id: '', name: '' }
  serialNumberStart: number = 0
  serialNumberEnd: number = 0
  lessThan: number = 0
  listOfDiscrepancies: ISelect = { name: '', value: '' }
  excel: ISelect = { name: '', value: '' }

  taskType: string = ''

  customSelectRole: ListElement = { id: '', name: '' }

  dump (item: ReportForm): ReportForm {
    const result: any = {}
    result.project = item.project
    if (item.groupBy.value) result.group_by = item.groupBy.value
    if (item.include.value) result.include = item.include.value
    if (item.moreThan) result.more_than = item.moreThan
    if (item.discrepancyIn.value) result.discrepancy_in = item.discrepancyIn.value
    if (item.auditor?.id) result.auditor = item.auditor.id
    if (item.serialNumberStart) result.serial_number_start = item.serialNumberStart
    if (item.serialNumberEnd) result.serial_number_end = item.serialNumberEnd
    if (item.lessThan) result.less_than = item.lessThan
    if (item.listOfDiscrepancies) result.only_discrepancies = item.listOfDiscrepancies.value !== 'all'
    if (item.excel.value) result.excel = item.excel.value
    if (item.taskType) result.task_type = item.taskType

    return result
  }
}

export {
  Report,
  ReportForm, groupByList,
  includeList, reportData
}
