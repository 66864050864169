import { store } from '@/store'
import { ButtonLoader } from '@/UI/loader'
import { defineComponent, PropType } from 'vue'
import { Report } from './types'

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Report>,
      required: true
    },
    toggle: {
      type: Function as PropType<(item: Report) => void>,
      required: true
    },
    generateReport: {
      type: Function as PropType<(item: Report, loadKey: string) => void>,
      required: true
    },
    idx: {
      type: String,
      required: true
    }
  },
  setup (props) {
    return () => (
      <div class="report-card">
        <p>{props.item.name}</p>
        {store.currentProject.id && <button
          onClick={() => { props.toggle(props.item) }}
          class="default-button"
        >
          Сформировать отчёт
        </button>}
      </div>
    )
  }
})
