interface IProp {
  active: boolean
}

export const RadioButton = ({ active }: IProp) => {
  return (
    <div class={{
      'radio-button': true,
      active
    }}></div>
  )
}
