import { RadioButton } from './radio-button'

interface ISelect {
  name: ''
  value: ''
}

interface IProp {
  items: ISelect[]
  onValuChange: (item: ISelect) => void
  activeElement: ISelect|null
}

export const RadioButtonGroup = ({ items, onValuChange, activeElement }: IProp) => {
  return (
    <div class='radio-button-group'>
      {items.map(item =>
        <div class='radio-button-item' onClick={() => onValuChange(item)}>
          <RadioButton active={activeElement?.value === item.value}/>
          <span class='radio-button-item-title'>{item.name}</span>
        </div>
      )}
    </div>
  )
}
