import axios from '@/axios'
import { useScrollPagination } from 'apptimizm-ui'
import usePaginatedBackend from 'apptimizm-ui/src/composables/use-paginated-api'
import { defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'
import { Employee, EmployeeMeta } from '../employee/types'
import { ReportForm } from './types'
import { store } from '@/store'

interface ListElement {
  id: string
  name: string
}

export default defineComponent({
  props: {
    form: {
      type: Object as PropType<Ref<ReportForm>>,
      required: true
    },
    setItem: {
      type: Function as PropType<(item: ListElement) => void>,
      required: true
    }
  },
  setup (props) {
    const employeeMeta = new EmployeeMeta()
    const root = ref(null) as unknown as Ref<HTMLElement>
    const target = ref(null) as unknown as Ref<HTMLElement>
    const params = ref<string>(props.form.value.customSelectRole.id)

    const { items, reload, loadNext } = usePaginatedBackend(
      employeeMeta.endpoint,
      axios,
      ref({
        role: params,
        project: store.currentProject.id
      }),
      ref(10),
      'page',
      (v: any) => employeeMeta.load(v),
      true,
      'results',
      'count'
    )

    useScrollPagination(loadNext, target, root)

    onMounted(reload)

    watch(() => props.form.value.customSelectRole.id, () => {
      params.value = props.form.value.customSelectRole.id
      reload()
    })

    return () => (
      <div class='auditor-report'>
        <div class='auditor-report-header'>
          <span>Номер</span>
          <span>Пользователи</span>
        </div>
        <div class='auditor-report-list' ref={root}>
          {items.value.map((item: Employee) =>
            <div
              key={item.id}
              class={{
                'auditor-report-item': true,
                active: props.form.value.auditor?.id === item.id
              }}
              onClick={() => { props.setItem({ id: item.id, name: item.username }) }}
            >
              <span>{item.serialNumber}</span>
              <span>{item.username}</span>
            </div>
          )}
          <div ref={target}></div>
        </div>
      </div>
    )
  }
})
