import { defineComponent } from '@vue/runtime-core'
import { ModalForm, RelationSelect } from 'apptimizm-ui'
import { Project, ProjectMeta } from '../projects/types'
import { computed, onMounted, ref } from 'vue'
import axios from '@/axios'
import { mutations, store } from '@/store'
import { Report, reportData, ReportForm } from './types'
import ReportCard from './report-card'
import './style.sass'
import { host } from '@/const'
import { getCeleryTask } from '@/core/celery'

export default defineComponent({
  setup () {
    const projectMeta = new ProjectMeta()
    const endpoint = host + '/api/v1/reports/'
    const items = ref<Report[]>([...reportData])
    const isProjectSelected = computed(() => !!store.currentProject.id)

    const form = ref<ReportForm>(new ReportForm())
    const showModal = ref<boolean>(false)
    const currentItem = ref<Report|null>(null)
    const toggle = (item?: Report) => {
      if (item) currentItem.value = item
      else currentItem.value = null
      showModal.value = !showModal.value
      form.value = new ReportForm()
      form.value.project = store.currentProject.id
    }

    const isLoading = ref<{ [code: string]: boolean }>({})

    const generateReport = async (item: Report, loadKey: string) => {
      isLoading.value[loadKey] = true

      try {
        const { data } = await axios.post(endpoint + item.endpoint, form.value.dump(form.value))
        const { data: x } = await getCeleryTask(data.task_id)
        if (x.result) {
          window.open(x.result)
          mutations.pushNotification('Отчет сформирован')
          if (showModal.value) toggle()
        }
        if (x.errors) mutations.pushNotification('Ошибка формирования отчета', true)
      } catch (e) {
        mutations.pushNotification('Ошибка формирования отчета', true)
        isLoading.value[loadKey] = false
      }

      isLoading.value[loadKey] = false
    }

    const submit = () => {
      if (!currentItem.value) return

      generateReport(currentItem.value, 'inModal')
    }

    onMounted(async () => {
      if (!isProjectSelected.value && localStorage.projectID) {
        try {
          const result = projectMeta.load((await axios.get(`${projectMeta.endpoint}${localStorage.projectID}`)).data)
          if (result?.id) {
            mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
            form.value.project = result.id
          }
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }
    })

    const reportModal = () => (
      <ModalForm cancel={toggle} confirm={() => {}} header={currentItem.value?.name || ''}
        slots={{
          default: () => currentItem.value?.modal
            ? <>
              {currentItem.value.description && <span style={{
                color: '#9DA6B6'
              }}>{currentItem.value.description}</span>}
              {currentItem.value.modal(form).body()}
            </>
            : <div></div>,
          buttons: () => currentItem.value?.modal ? currentItem.value.modal(form).footer(submit, isLoading.value.inModal) : <div></div>
        }}
      />
    )

    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Отчеты</h1>
          <div class="page-top-panel-row mt-5">
          </div>
        </div>
        {isProjectSelected.value && <div class="layout report">
          {items.value.map((item: Report, idx: number) =>
            <ReportCard
              item={item}
              toggle={toggle}
              generateReport={generateReport}
              idx={idx.toString()}
            />
          )}
        </div>}
        {showModal.value && reportModal()}
      </div>
    )
  }
})
